import React from 'react';
import { Link } from 'gatsby';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';

const Careers = () => (
  <div>
    {/* <Header /> */}

    <header className="sticky top-0 bg-white shadow">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <Link className="flex text-2xl" to="/">
          <div className="w-40">
            <img src={'../../../sg-logo.png'} alt="SG Innovations logo" />
          </div>
          {/* <div className="pt-12">SG Innovations </div> */}
        </Link>
        <div className="flex mt-4 sm:mt-0">
          <Link className="px-4" to="/">
            Home
          </Link>
          <Link className="px-4" to="/contact">
            Contact
          </Link>
        </div>
      </div>
    </header>

    <div class="bg-blue-600 text-white py-8">
      <div class="container mx-auto text-center">
        <h1 class="text-4xl font-semibold">Join Our Dynamic Team</h1>
        <p class="mt-2">Shape the future of technology with us!</p>
      </div>
    </div>
    <div class="container mx-auto mt-8">
      <h2 class="text-2xl font-semibold mb-4">Current Open Positions</h2>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="bg-white shadow p-4 rounded">
          <h3 class="text-xl font-semibold mb-2">Senior C# ASP.Net Engineer</h3>
          <p>Minimum 4 years experience</p>
          <Link className="mt-4 inline-block text-blue-500 hover:underline" to="#details">
            Learn More
          </Link>
        </div>

        <div class="bg-white shadow p-4 rounded">
          <h3 class="text-xl font-semibold mb-2">Senior Frontend Developer(VUE)</h3>
          <p>Minimum 2 years experience in VUE</p>
          <Link className="mt-4 inline-block text-blue-500 hover:underline" to="#details">
            Learn More
          </Link>
        </div>

        <div class="bg-white shadow p-4 rounded">
          <h3 class="text-xl font-semibold mb-2">AI/ML engineer</h3>
          <p>Minimum 2 years experience</p>
          <Link className="mt-4 inline-block text-blue-500 hover:underline" to="#details">
            Learn More
          </Link>
        </div>
      </div>
    </div>

    <div class="bg-gray-200 py-4 mt-10">
      <div class="container mx-auto text-center">
        <h2 class="text-2xl font-semibold mb-4">Ready to Make an Impact?</h2>
        <p>
          If you're excited about the possibilities that technology brings and are eager to
          contribute your skills to a dynamic team, we'd love to hear from you.
        </p>
        <Link className="mt-4 inline-block bg-blue-600 text-white py-2 px-6 rounded" to="/contact">
          Apply Now
        </Link>
      </div>
    </div>
    <Footer />
  </div>
);

export default Careers;
